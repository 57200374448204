<section>
    <div class="container-fluid">
        <div class="">
            <div class="row">
                <div class="col-sm-12">
                    <div class="app-box empty-punch">
                        
                        <ngx-spinner size="medium" color="#fff" type="ball-beat" [fullScreen]="true">
                            <p style="font-size: 30px; color: #fff">Your Payment is being Processed. Please wait.</p>
                        </ngx-spinner>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</section>