import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from "@angular/router";

@Component({
  selector: 'mobile-app-process-payment',
  templateUrl: './mobile-process-payment.component.html',
  styleUrls: ['./mobile-process-payment.component.scss']
})
export class MobileProcessPaymentComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    }

}
