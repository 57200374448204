import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ModuleLoadGuard } from "projects/common/src/lib";
import { ValidateComponent } from "./validate/validate.component";
import { MobileProcessPaymentComponent } from "./screens/mobile-process-payment/mobile-process-payment.component";

const routes: Routes = [
  
  {
    path: "payu",
    component: MobileProcessPaymentComponent,
    children: [
        { path: "", redirectTo: "recharge-process", pathMatch: "full" },
    
        { path: "recharge-process", component: MobileProcessPaymentComponent },
    ],
  },
  {
    path: "validate",
    component: ValidateComponent,
  },
  {
    path: "",
    canLoad: [ModuleLoadGuard],
    loadChildren: () => import("./screens/screens.module").then((m) => m.ScreensModule),
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
